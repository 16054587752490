import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject, forkJoin, take} from 'rxjs';
import {ContentCategory, TacticsMetrics} from '../models';
import {StateSessionService} from '../facades';
import {UserSessionStoreService} from '@simplifi/core/index';
import {Numbers} from '@simplifi/shared/enums';
import {PlanFacadeService} from '../../services';
import {TargetMediaType} from '../models/target-media-type.model';
import {PlanView} from '../../../../shared/models/plan-view.model';
import {Words} from '../interfaces';
import {BudgetTypes} from '../models/budget-types.model';
import {GeneralAiResponse} from '@simplifi/shared/interfaces';

@Injectable()
export class CreatePlanService {
  constructor(
    private readonly stateFacade: StateSessionService,
    private readonly store: UserSessionStoreService,
    private readonly planFacadeService: PlanFacadeService,
  ) {}

  presence!: NodeJS.Timeout;
  plan: PlanView = new PlanView();
  campaignGoalIdMap = new Map<string, string>();
  contentCategoryIdMap = new Map<string, string>();
  budget = new BudgetTypes();

  private planDataSubject = new Subject<PlanView>();
  planData$ = this.planDataSubject.asObservable();

  /**
   * Updates the plan data and notifies all subscribers.
   *
   * @param {PlanView} planData - The updated plan data.
   * @returns {void}
   */
  setPlanData(planData: PlanView): void {
    this.planDataSubject.next(planData);
  }

  private removeLocationSubject = new Subject<Words>();
  removeLocations$ = this.removeLocationSubject.asObservable();

  /**
   * Removes specified locations and notifies all subscribers.
   *
   * @param {Words} location - The location to remove.
   * @returns {void}
   */
  removeLocations(location: Words): void {
    this.removeLocationSubject.next(location);
  }

  private budgetSubject = new BehaviorSubject<BudgetTypes>(this.budget);
  budget$ = this.budgetSubject.asObservable();
  setBudget(data: BudgetTypes) {
    this.budgetSubject.next(data);
  }

  private totalPlanBudgetSubject = new Subject<number>();
  totalPlanBudget$ = this.totalPlanBudgetSubject.asObservable();

  /**
   * Updates the total plan budget and notifies all subscribers.
   *
   * @param {number} data - The updated total plan budget.
   * @returns {void}
   */
  setTotalPlanBudget(data: number): void {
    this.totalPlanBudgetSubject.next(data);
  }

  private targetMediaTypeSubject = new Subject<TargetMediaType>();
  targetMediaType$ = this.targetMediaTypeSubject.asObservable();

  /**
   * Updates the target media type and notifies all subscribers.
   *
   * @param {TargetMediaType} data - The updated target media type data.
   * @returns {void}
   */
  setTargetMediaType(data: TargetMediaType): void {
    this.targetMediaTypeSubject.next(data);
  }

  private readonly chatMessageUpdateSubject = new Subject<GeneralAiResponse>();
  chatMessageUpdate$ = this.chatMessageUpdateSubject.asObservable();

  /**
   * Updates the target media type and notifies all subscribers.
   *
   * @param {TargetMediaType} data - The updated target media type data.
   * @returns {void}
   */
  setChatMessageUpdate(data: GeneralAiResponse): void {
    this.chatMessageUpdateSubject.next(data);
  }

  private mediaTypeDeleteSubject = new Subject<boolean>();
  mediaTypeDelete$ = this.mediaTypeDeleteSubject.asObservable();

  /**
   * Notifies all subscribers about the deletion of a media type.
   *
   * @param {boolean} data - The deletion status.
   * @returns {void}
   */
  deleteMediaType(data: boolean): void {
    this.mediaTypeDeleteSubject.next(data);
  }

  planFormStateObv = new Subject<boolean>();

  /**
   * Updates the plan form state and notifies all subscribers.
   *
   * @param {boolean} data - The updated plan form state.
   * @returns {void}
   */
  setPlanFormStateObv(data: boolean): void {
    this.planFormStateObv.next(data);
  }

  private generalDateSubject = new Subject<{
    startDate: Date;
    endDate: Date;
  }>();
  generalDate$ = this.generalDateSubject.asObservable();

  /**
   * Updates the general date range (start and end dates) and notifies all subscribers.
   *
   * @param {{ startDate: Date; endDate: Date }} generalDate - The updated start and end dates.
   * @returns {void}
   */
  setGeneralDate(generalDate: {startDate: Date; endDate: Date}): void {
    this.generalDateSubject.next(generalDate);
  }

  private discardResetPlanUnsaveDataSubject = new Subject<boolean>();
  discardResetPlanUnsaveData$ =
    this.discardResetPlanUnsaveDataSubject.asObservable();

  /**
   * Notifies all subscribers about discarding or resetting unsaved plan data.
   *
   * @param {boolean} data - The status indicating whether to discard or reset unsaved data.
   * @returns {void}
   */
  setDiscardResetPlanUnsaveData(data: boolean): void {
    this.discardResetPlanUnsaveDataSubject.next(data);
  }

  locationTypeObv = new Subject<PlanView>();

  /**
   * Updates the location type in the plan and notifies all subscribers.
   *
   * @param {PlanView} plan - The updated plan data with the new location type.
   * @returns {void}
   */
  public setlocationType(plan: PlanView): void {
    this.locationTypeObv.next(plan);
  }

  savePlanObv = new Subject<string>();

  /**
   * Notifies all subscribers with the URL of the saved plan.
   *
   * @param {string} url - The URL of the saved plan.
   * @returns {void}
   */
  public setSavePlanObv(url: string): void {
    this.savePlanObv.next(url);
  }

  /**
   * Processes campaign goals and content categories by fetching data from the API
   * and populating internal maps for easy reference.
   *
   * @returns {void}
   */
  processCampaignGoalsAndContentCategories(): void {
    const apiArray = [
      this.planFacadeService.getCampaignGoalData(),
      this.planFacadeService.getContentCategoriesData(),
    ];
    forkJoin(apiArray)
      .pipe(take(1))
      .subscribe({
        next: res => {
          res[0].forEach(goal => {
            this.campaignGoalIdMap.set(goal.name, goal.id);
          });
          (res[1] as ContentCategory[]).forEach(contentCategory => {
            this.contentCategoryIdMap.set(
              contentCategory.name,
              contentCategory.id,
            );
          });
        },
      });
  }

  /**
   * Initiates a session presence interval that periodically checks the state session presence.
   *
   * The interval is based on a predefined time value (`Numbers.threeHundred` seconds).
   *
   * @returns {void}
   */
  sessionPresence(): void {
    this.presence = setInterval(() => {
      this.stateFacade
        .stateSessionPresence(this.store.getStateSession())
        .subscribe();
    }, Numbers.threeHundred * 1000);
  }

  /**
   * Clears the session presence interval, stopping the periodic checks.
   *
   * @returns {void}
   */
  clearPresence(): void {
    clearInterval(this.presence);
  }

  calculateTotal(tactics: {[key: string]: TacticsMetrics}) {
    let totalValue = 0;
    totalValue = Object.values(tactics).reduce(
      (acc, tactic) => acc + Number(tactic.totalCpm),
      0,
    );
    return totalValue;
  }

  private generalSummaryCountSubject = new Subject<number>();
  generalSummaryCount$ = this.generalSummaryCountSubject.asObservable();

  /**
   * Updates the general summary count and notifies all subscribers.
   *
   * @param {number} data - The updated general summary count.
   * @returns {void}
   */
  setGeneralSummaryCount(data: number): void {
    this.generalSummaryCountSubject.next(data);
  }

  /**
   * Calculates the count of general data fields that are populated.
   *
   * This method checks several properties in the `plan` object and counts how many of them are not null or undefined.
   *
   * @returns {number} - The count of populated general data fields.
   */
  getGeneralDataCount(): number {
    let count = 0;
    const properties = [
      this.plan.startDate,
      this.plan.campaignGoalName,
      this.plan.deviceTypes,
      this.plan.budgetType,
      this.plan.contentCategoriesName &&
      this.plan.contentCategoriesName.length > 0
        ? this.plan.contentCategoriesName
        : undefined,
    ];
    properties.forEach(property => {
      if (property) {
        count++;
      }
    });
    return count;
  }
}
