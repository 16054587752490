export class HighChartsDonutDataset {
  name: string;
  value: number;
  color: string;
  constructor() {
    this.name = '';
    this.value = 0;
    this.color = '#2B72FB';
  }
}
