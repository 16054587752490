import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {Item} from '@simplifi/shared/interfaces';
import {SelectModelType} from '@simplifi/shared/types/select-model.type';

@Component({
  selector: 'simplifi-select-box',
  templateUrl: './select-box.component.html',
  styleUrl: './select-box.component.scss',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class SelectBoxComponent<T> {
  @Input() form!: NgForm;
  @Input() bindLabel = 'name';
  @Input() bindValue = 'value';
  @Input() id!: string;
  @Input() name!: string;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() disabled = false;
  @Input() inputLabel = true;
  @Input() horizontalField = false;
  @Input() readonly = false;
  @Input() multiple = false;
  @Input() selectableGroup = true;
  @Input() optionsWithIcons = false;
  @Input() showPrefix = false;
  @Input() prefixIconSrc!: string;
  @Input() prefixIconClass!: string;
  @Input() searchable = false;
  @Input() sort = true;
  @Output() selectModelChange = new EventEmitter();
  @Input() validationMessage = '';

  private _items: Item<T>[] = [];
  @Input()
  set items(value: Item<T>[]) {
    this._items = value;
    if (this._items.length > 0 && this.selectModel) {
      this.reorderItems(this.selectModel as T[]);
    }
  }
  get items(): Item<T>[] {
    return this._items;
  }

  private _selectModel: SelectModelType;
  @Input()
  set selectModel(value: SelectModelType) {
    this._selectModel = value;
    if (this._items.length > 0 && this._selectModel) {
      this.reorderItems(this._selectModel as T[]);
    }
  }
  get selectModel(): SelectModelType {
    return this._selectModel;
  }

  onChange(event: Item<T>[keyof Item<T>]) {
    // Convert event to an array if it's not already
    const eventValues = Array.isArray(event) ? event : [event];
    this.reorderItems(eventValues);

    this.selectModelChange.emit(event);
  }

  // Helper function to sort items alphabetically based on bindLabel
  sortItemsAlphabetically = (items: Item<T>[]) => {
    return items.sort((a, b) => {
      if (a[this.bindLabel] > b[this.bindLabel]) {
        return 1;
      } else if (b[this.bindLabel] > a[this.bindLabel]) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  private reorderItems(eventValues: T[]) {
    if (this.sort) {
      // Filter and sort the selected items
      const selectedItems = this.sortItemsAlphabetically(
        this._items.filter(item => eventValues.includes(item[this.bindValue])),
      );

      // Filter and sort the remaining items
      const remainingItems = this.sortItemsAlphabetically(
        this._items.filter(item => !eventValues.includes(item[this.bindValue])),
      );

      // Create a temporary array and combine the sorted selected items with the sorted remaining items
      const tempItems = [...selectedItems, ...remainingItems];

      // Assign the temporary array to this.items
      this._items = tempItems;
    }
  }
}
