import {
  Directive,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[simplifiChatSizeAdjust]',
})
export class ChatSizeAdjustDirective implements AfterViewInit, OnDestroy {
  private scrollableElement!: HTMLElement;
  private resizeObserver!: ResizeObserver;
  private initialParentHeight!: number;
  private initialScrollableHeight!: number;
  private routerEventsSubscription!: Subscription;

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly router: Router,
  ) {}

  ngAfterViewInit() {
    this.scrollableElement = this.el.nativeElement.querySelector(
      '.scrollable',
    ) as HTMLElement;

    setTimeout(() => this.calculateInitialHeights(), 0);

    this.resizeObserver = new ResizeObserver(() => {
      this.adjustHeight();
    });

    this.resizeObserver.observe(this.el.nativeElement);

    this.routerEventsSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => this.calculateInitialHeights(), 0);
      }
    });
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  private calculateInitialHeights() {
    this.initialParentHeight = this.el.nativeElement.clientHeight;
    this.initialScrollableHeight = this.scrollableElement.clientHeight;

    this.adjustHeight();
  }

  private adjustHeight() {
    if (this.scrollableElement && this.initialParentHeight !== undefined) {
      const currentParentHeight = this.el.nativeElement.clientHeight;
      const heightDifference = currentParentHeight - this.initialParentHeight;

      const adjustedHeight = this.initialScrollableHeight + heightDifference;

      this.renderer.setStyle(
        this.scrollableElement,
        'height',
        `${adjustedHeight}px`,
      );
    }
  }
}
