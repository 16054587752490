import {IGridColumn} from '@simplifi/shared/modules';
import {Percentages, TacticsMetrics, Totals} from '../models';
import {Pagination} from '@simplifi/shared/models';
import {CustomGridHeaderInfoComponent} from '@simplifi/shared/components';
import {CustomTableHeaderComponent} from '@simplifi/shared/components/custom-table-header/custom-table-header.component';
import {ValueFormatterParams} from 'ag-grid-community';

export const DEFAULT_TITLE = 'Create Plan';
export const BUDGET_TYPES = [
  {id: 'daily', name: 'Daily'},
  {id: 'weekly', name: 'Weekly'},
  {id: 'monthly', name: 'Monthly'},
  {id: 'lifetime', name: 'Lifetime'},
];

export enum BudgetType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  LIFETIME = 'lifetime',
}

export const TargetConstants = {
  keywordSearchFieldLabel: `Type keywords or descriptions to generate suggestions. You can add more words in text box at anytime.`,
  ztvSearchFieldLabel:
    'Add zip codes. You can add more zip codes in text box at anytime.',
  ztvSectionName: 'Zip Adviser',
  contextualSectionName: 'Contextual',
  ztvSectionIconImg: '/assets/images/campaign-plan/ztv.svg',
  contextualSectionIcon: 'el-icon-contextual',
  ztvWordLabel: 'Zip Codes',
  contextualWordLabel: 'Contextual',
  ztvSectionId: 'zip-code',
  keywordSuggestText: `suggested keywords added to your plan`,
  contextualSearchFieldLabel:
    'Add contextual. You can add more page contexts in text box at anytime.',
  addressableSearchFieldLabel: `Describe the demographic you'd like to target. You can add more demographics in text box at anytime.`,
  keywordPlaceholderText: `Enter keywords such as accounting, fitness, health, etc.`,
  addressablePlaceholderText: `Enter demographics such as men and women aged 18-25 with children and pets.`,
  contextualPlaceholderText: `Enter contextual or contextual categories such as business, pets, etc.`,
  zipSelectPlaceholderText: `Enter ZIP code(s) such as 10101, 32457, etc.`,
};

export const TargetKeys = {
  DISPLAY_KEY: 'display',
  VIDEO_KEY: 'video',
  OTT_KEY: 'ottCtv',
};

export const ZTV_IMAGE_PATH = '/assets/images/campaign-plan/ztv.svg';
export const TACTICS_ICONS = {
  Addressable: 'el-icon-audience',
  Contextual: 'el-icon-contextual',
  Keywords: 'el-icon-search-eye',
  ZipSelect: 'el-icon-ztv',
};

export const TACTICS_DOT_COLOR = {
  Addressable: 'tac-blue',
  Contextual: 'tac-green',
  Keywords: 'tac-cyan',
  ZipSelect: 'tac-orange',
};

export const TACTICS_CLASS = {
  Addressable:
    'dark-blue-bg shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center',
  Contextual:
    'dark-green-bg shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center',
  Keywords:
    'cyan-bg shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center',
  ZipSelect:
    'orange-bg shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center',
};

export const TACTICS_TOTAL_SPEND_LABLE = 'Total Spend';

export const TACTICS_TO_CONVERT_IN_THOUSANDS = ['Household', 'Population', ''];

export const TACTICS_TABS_CONFIGURATIONS = [
  {
    label: 'TOTAL SPEND',
    tableHeaderLabel: 'TOTAL SPEND',
    totalLabel: TACTICS_TOTAL_SPEND_LABLE,
    summaryKey: 'Budget' as keyof Percentages,
    totalValueKey: 'budget' as keyof Totals,
    tacticsKey: 'totalBudget' as keyof TacticsMetrics,
    totalPrefix: '$',
  },
  {
    label: 'TOTAL ITEMS',
    tableHeaderLabel: 'ITEMS',
    totalLabel: 'Items',
    summaryKey: 'TargetCount' as keyof Percentages,
    totalValueKey: 'items' as keyof Totals,
    tacticsKey: 'targetCount' as keyof TacticsMetrics,
    totalPrefix: '',
  },
  {
    label: 'IMPRESSION',
    totalLabel: 'Total Impressions',
    tableHeaderLabel: 'IMPRESSION',
    summaryKey: 'Impressions' as keyof Percentages,
    totalValueKey: 'impressions' as keyof Totals,
    tacticsKey: 'totalImpressions' as keyof TacticsMetrics,
    totalPrefix: '',
  },
  {
    label: 'CPM',
    tableHeaderLabel: 'CPM',
    totalLabel: 'CPM',
    summaryKey: 'CPM' as keyof Percentages,
    totalValueKey: 'cpm' as keyof Totals,
    tacticsKey: 'totalCpm' as keyof TacticsMetrics,
    totalPrefix: '',
  },
  {
    label: 'household',
    tableHeaderLabel: 'household',
    totalLabel: 'Household',
    summaryKey: 'household' as keyof Percentages,
    totalValueKey: 'household' as keyof Totals,
    tacticsKey: 'household' as keyof TacticsMetrics,
    totalPrefix: '',
  },
  {
    label: 'population',
    tableHeaderLabel: 'population',
    totalLabel: 'Population',
    summaryKey: 'population' as keyof Percentages,
    totalValueKey: 'population' as keyof Totals,
    tacticsKey: 'population' as keyof TacticsMetrics,
    totalPrefix: '',
  },
];

export enum TacticKeys {
  AUDIENCE = 'ADDRESSABLE',
  CONTEXTUAL = 'CONTEXTUAL',
  KEYWORD = 'KEYWORD',
  ZTV = 'ZipSelect',
}

const backgroundClass = 'grey-background';
const rightAlign = 'justify-end';
const centerAlign = 'justify-center';

export const SUMMARY_RESULTS_COL_DEF_ZTV_SUMMARY = [
  {
    field: 'zipCount',
    headerName: 'TOTAL ZIP CODES',
    headerClass: backgroundClass,
  },
  {
    field: 'totalPopulations',
    headerName: 'TOTAL POPULATION',
    headerClass: backgroundClass,
    valueFormatter: numberFormatter,
  },
  {
    field: 'totalHouseholds',
    headerName: 'TOTAL HOUSEHOLDS',
    headerClass: backgroundClass,
    valueFormatter: numberFormatter,
  },
  {
    field: 'averageNonLinearTvs',
    headerName: 'AVG CTV VIEW %',
    headerClass: backgroundClass,
  },
  {
    field: 'averageLinearTvs',
    headerName: 'AVG LINEAR VIEWERSHIP %',
    headerClass: backgroundClass,
  },
  {
    field: 'incomeMedian',
    headerName: 'HOUSEHOLD INCOME MEDIAN',
    headerComponent: CustomGridHeaderInfoComponent,
    minWidth: 200,
    valueFormatter: numberFormatter,
    headerClass: backgroundClass,
  },
];

export const DONUT_BACKGROUND_COLOR = [
  '#754ad0',
  '#099b7f',
  '#00d1ff',
  '#ff9d00',
];
export const DONUT_HOVER_BACKGROUND_COLOR = [
  '#754ad0',
  '#099b7f',
  '#00d1ff',
  '#ff9d00',
];

export const DONUT_CONSTANTS = {
  hoverBackGroundColor: DONUT_HOVER_BACKGROUND_COLOR,
  backGroundColor: DONUT_BACKGROUND_COLOR,
  border: 0,
  totalText: 'Budget',
  totalValuePrefix: '$',
};

export const RESULT_LOCATION_METRO = 'LOCATION METRO';
export const RESULT_LOCATION_CITY = 'LOCATION CITY';

export const RESULT_ZIPCODE_DATA_TABLE_COLUMNS: IGridColumn[] = [
  {
    field: 'zipCode',
    headerName: 'LOCATION POSTAL CODE',
    headerClass: backgroundClass,
    minWidth: 200,
  },
  {
    field: 'state',
    headerName: 'LOCATION REGION',
    headerClass: backgroundClass,
    minWidth: 140,
  },
  {
    field: 'city',
    headerName: RESULT_LOCATION_CITY,
    headerClass: backgroundClass,
    minWidth: 140,
  },
  {
    field: 'metro',
    headerName: RESULT_LOCATION_METRO,
    headerClass: backgroundClass,
    cellStyle: {textAlign: 'center'},
    minWidth: 140,
  },
  {
    field: 'totalImpressionsAddressable',
    headerName: 'PERFORMANCE IMPRESSIONS ADDRESSABLE',
    headerComponent: CustomTableHeaderComponent,
    headerClass: centerAlign,
    cellClass: rightAlign,
    valueFormatter: numberFormatter,
    minWidth: 200,
  },
  {
    field: 'totalSpendsAddressable',
    headerName: 'PERFORMANCE SPEND ADDRESSABLE',
    headerComponent: CustomTableHeaderComponent,
    headerClass: centerAlign,
    cellClass: rightAlign,
    valueFormatter: currencyFormatter,
    minWidth: 200,
  },

  {
    field: 'totalImpressionsContextual',
    headerName: 'PERFORMANCE IMPRESSIONS CONTEXTUAL',
    headerComponent: CustomTableHeaderComponent,
    headerClass: centerAlign,
    cellClass: rightAlign,
    valueFormatter: numberFormatter,
    minWidth: 200,
  },
  {
    field: 'totalSpendsContextual',
    headerName: 'PERFORMANCE SPEND CONTEXTUAL',
    headerComponent: CustomTableHeaderComponent,
    headerClass: centerAlign,
    cellClass: rightAlign,
    popoverField: 'totalSpendsContextual',
    valueFormatter: currencyFormatter,
    minWidth: 200,
  },

  {
    field: 'totalImpressionsKeywords',
    headerName: 'PERFORMANCE IMPRESSIONS KEYWORDS',
    headerComponent: CustomTableHeaderComponent,
    headerClass: centerAlign,
    cellClass: rightAlign,
    valueFormatter: numberFormatter,
    minWidth: 200,
  },
  {
    field: 'totalSpendsKeywords',
    headerName: 'PERFORMANCE SPEND KEYWORDS',
    headerComponent: CustomTableHeaderComponent,
    headerClass: centerAlign,
    cellClass: rightAlign,
    valueFormatter: currencyFormatter,
    minWidth: 200,
  },
  {
    field: 'totalImpressionsZtv',
    headerName: 'PERFORMANCE IMPRESSIONS ZIP ADVISER',
    headerComponent: CustomTableHeaderComponent,
    headerClass: centerAlign,
    cellClass: rightAlign,
    valueFormatter: numberFormatter,
    minWidth: 200,
  },
  {
    field: 'totalSpendsZtv',
    headerName: 'PERFORMANCE SPEND ZIP ADVISER',
    headerComponent: CustomTableHeaderComponent,
    headerClass: centerAlign,
    cellClass: rightAlign,
    valueFormatter: currencyFormatter,
    minWidth: 200,
  },
];

export const PAGINATION_INIT_DATA = new Pagination({
  rowCount: 0,
  currentPage: 1,
  rowLimit: 10,
});

export function formatAccordingToType(
  params: ValueFormatterParams,
  type: 'decimal' | 'currency' | 'percent' | 'unit',
): string {
  const value = params.value;
  if (value == null) {
    return '';
  }
  return new Intl.NumberFormat('en-US', {
    style: type,
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(+(value ?? 0));
}
export function currencyFormatter(params: ValueFormatterParams): string {
  return formatAccordingToType(params, 'currency');
}
export function numberFormatter(params: ValueFormatterParams) {
  return formatAccordingToType(params, 'decimal');
}
export const enum TileKeys {
  flightDate = 'flightDate',
  blendedCpm = 'blendedCpm',
  householdReach = 'householdReach',
  populationReach = 'populationReach',
  locationCount = 'locationCount',
}
export const TILES: TileKeys[] = [
  TileKeys.flightDate,
  TileKeys.blendedCpm,
  TileKeys.householdReach,
  TileKeys.populationReach,
  TileKeys.locationCount,
];
export interface TileKeysInterface {
  tileIcon: string;
  tileTextUpper: string;
  tileTextPrefix: string;
  tileText: string;
  tileTextLower: string;
}

export const RESULT_TILE_CONFIGURATION: Record<string, TileKeysInterface> = {
  flightDate: {
    tileIcon: ' el-icon-date',
    tileTextUpper: '',
    tileTextPrefix: '',
    tileText: '',
    tileTextLower: 'Flight Dates',
  },
  blendedCpm: {
    tileIcon: ' el-icon-currency',
    tileTextUpper: '',
    tileTextPrefix: '$',
    tileText: '',
    tileTextLower: 'Blended CPM',
  },
  householdReach: {
    tileIcon: ' el-icon-home',
    tileTextUpper: 'Estimated',
    tileTextPrefix: '',
    tileText: '',
    tileTextLower: 'Households Reached',
  },
  populationReach: {
    tileIcon: ' el-icon-audience',
    tileTextUpper: 'Estimated',
    tileTextPrefix: '',
    tileText: '',
    tileTextLower: 'People Reached',
  },
  locationCount: {
    tileIcon: ' el-icon-location-outline',
    tileTextUpper: '',
    tileTextPrefix: '',
    tileText: '',
    tileTextLower: 'Locations',
  },
};

export const BLENDED_CPM_DEFAULT_VALUE = `0`;
export const PLAN_TARGET_PDF = 'downloadPdf';

export const VALUES_TO_SHOW_IN_THOUSANDS = [
  'Household',
  'Population',
  'household',
  'population',
];
