export enum LocationType {
  StateCity = 'statecity',
  Metro = 'metro',
  City = 'city',
  Zipcode = 'zipcode',
}

export enum StateCity {
  State = 'STATE',
  City = 'CITY',
}

export const locationTypeData = [
  {name: 'State/City', value: LocationType.StateCity},
  {name: 'Metro', value: LocationType.Metro},
  {name: 'Zipcode', value: LocationType.Zipcode},
];

export enum locationAction {
  ViewMore = 'View More Locations',
}
