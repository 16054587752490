<div
  class="ng-select-wrapper el-form-item"
  [ngClass]="{'horizontal-field': horizontalField}"
>
  <label *ngIf="inputLabel" for="category" class="el-form-item__label">
    {{ label }}
  </label>
  <div class="el-form-item__content">
    <div class="el-input">
      <ng-select
        [items]="items"
        [bindLabel]="bindLabel"
        [bindValue]="bindValue"
        groupBy="selectedAllGroup"
        [selectableGroup]="true"
        [placeholder]="placeholder"
        [multiple]="multiple"
        [required]="required"
        [disabled]="disabled"
        [readonly]="readonly"
        name="{{ name || 'name' }}"
        [id]="id"
        [(ngModel)]="selectModel"
        (ngModelChange)="onChange($event)"
        [searchable]="searchable"
      >
        <ng-template ng-placeholder-tmp>
          <div class="ng-select-placeholder flex items-center">
            <ng-container *ngIf="showPrefix">
              <ng-container *ngIf="prefixIconSrc; else iconClass">
                <img
                  [src]="prefixIconSrc"
                  alt="prefix-icon"
                  class="prefix-icon ml-2"
                />
              </ng-container>
              <ng-template #iconClass>
                <i [class]="prefixIconClass" class="prefix-icon ml-2"></i>
              </ng-template>
            </ng-container>
            <span class="ml-2">{{ placeholder }}</span>
          </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <ng-container *ngIf="showPrefix">
            <ng-container *ngIf="prefixIconSrc; else iconClass">
              <img
                [src]="prefixIconSrc"
                alt="prefix-icon"
                class="prefix-icon ml-2"
              />
            </ng-container>
            <ng-template #iconClass>
              <i [class]="prefixIconClass" class="prefix-icon ml-2"></i>
            </ng-template>
          </ng-container>
          <div *ngFor="let item of items.slice(0, 3)">
            <div class="ng-value">
              <span
                class="ng-value-label"
                *ngIf="item.name && item.name.length > 20; else fullText"
                [matTooltip]="item.name"
              >
                {{ item.name | slice: 0 : 20 }}...
              </span>
              <ng-template #fullText>
                <span class="ng-value-label">{{ item.name }}</span>
              </ng-template>
              <span
                class="ng-value-icon right"
                (click)="clear(item)"
                aria-hidden="true"
                >×</span
              >
            </div>
          </div>
          <div *ngIf="items.length > 3">
            <div class="ng-value">
              <span class="ng-value-label">{{ items.length - 3 }} more</span>
            </div>
          </div>
        </ng-template>
        <ng-template *ngIf="optionsWithIcons" ng-option-tmp let-item="item">
          <div class="option-item flex items-center gap-x-2">
            <ng-container *ngIf="item.srcPath; else iconOptions">
              <span class="option-icon">
                <img [src]="item.srcPath" alt="" />
              </span>
              <div>{{ item.name }}</div>
            </ng-container>
            <ng-template #iconOptions>
              <span class="option-icon">
                <i [class]="item.iconClass"> </i>
              </span>
              <div>{{ item.name }}</div>
            </ng-template>
          </div>
        </ng-template>
      </ng-select>
      <div
        *ngIf="
          form?.form?.controls?.[name]?.invalid &&
          form.form.controls[name]?.errors?.['required'] &&
          form.form.controls[name]?.touched
        "
        class="el-form-item__error"
      >
        {{ validationMessage }}
      </div>
    </div>
  </div>
</div>
